import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import { Provider } from './provider';
import { ErrorBoundary } from 'react-error-boundary';
import { fetcher } from './components/fetcher';

const Fallback = () => {
  return (
    <div role="alert" style={{
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <p>Something went wrong</p>
    </div>
  );
};

const onError = (error: Error, info: React.ErrorInfo) => {
  return fetcher({
    url: '/error',
    method: 'post',
    reloadOnUnauthorized: false,
    body: {
      message: error.message,
      stack: info.componentStack,
    }
  });
};

const Main = () => {
  return (
    <StrictMode>
      <ErrorBoundary FallbackComponent={Fallback} onError={onError}>
        <Provider />
      </ErrorBoundary>
    </StrictMode>
  );
};

createRoot(document.getElementById('app')!).render(<Main />);