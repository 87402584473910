import { ClerkLoaded, ClerkProvider } from '@clerk/clerk-react';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { useDarkMode } from './hooks/use-dark-mode';
import { dark } from '@clerk/themes';
import { SWRConfig } from 'swr';
import { useState } from 'react';
import Snackbar, { Props as SnackbarProps } from './components/snackbar';
import { MutationError, fetcher } from './components/fetcher';
import { ThemeProvider } from '@mui/material';
import { lightTheme, darkTheme } from '@/theme';

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

export const Provider = () => {
  const prefersDarkMode = useDarkMode();
  const [snackbarState, setSnackbarState] = useState<SnackbarProps>({ open: false });
  let theme;
  if (prefersDarkMode) {
    theme = dark;
  }

  const onClose = (err: any) => {
    setSnackbarState({
      message: err.displayErrorMessage,
      severity: 'error',
      open: false
    });
  };

  const onError = (err: any) => {
    if (err instanceof MutationError) {
      setSnackbarState({
        open: true,
        message: err.displayErrorMessage,
        severity: 'error',
        onClose: () => {
          onClose(err);
        },
      });
    }
  };

  return (
    <ThemeProvider theme={prefersDarkMode ? darkTheme : lightTheme}>
      <SWRConfig value={{
        onError,
        fetcher,
        revalidateOnFocus: true,
      }}>
        <ClerkProvider publishableKey={PUBLISHABLE_KEY} appearance={{ baseTheme: theme, variables: { colorShimmer: 'rgba(0, 0, 0, 0)' }}}>
          <ClerkLoaded>
            <RouterProvider router={router} />
          </ClerkLoaded>
        </ClerkProvider>
        <Snackbar {...snackbarState} />
      </SWRConfig>
    </ThemeProvider>
  );
};