import { Container, CssBaseline } from '@mui/material';
import AppBar from '@/components/app-bar';
import { Outlet } from 'react-router-dom';
import { SignedIn, SignedOut, RedirectToSignIn } from '@clerk/clerk-react';
import './theme/styles.css';

export function Layout() {
  return (
    <>
      <CssBaseline />
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
      <SignedIn>
        <AppBar />
        <Container>
          <Outlet />
        </Container>
      </SignedIn>
    </>
  );
}


